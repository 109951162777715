<template>
  <div class="homeBanner">
    <section class="hero__slider--section style2">
      <div class="container" style="height: 50vw;">
        <b-carousel
            ref="myCarousel"
            id="testimonialSwiper"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            style="border-radius: 10px;overflow: hidden;height: 100%"
        >
          <router-link to="/auth-login">
            <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="url" img-alt="Cloud Mining Platform" img-title="cloud mining service provider">
            </b-carousel-slide>
<!--            <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="secondUrl" img-alt="Best Cloud Mining Websites" img-title="cloud mining technology Services">-->
<!--              <div class=" w-100">-->
<!--                <div class="title-heading"></div>-->
<!--              </div>-->
<!--            </b-carousel-slide>-->
<!--            <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="thirdUrl" img-alt="Cloud Mining Services" img-title="Cloud Mining Services">-->
<!--              <div class=" w-100">-->
<!--                <div class="title-heading"></div>-->
<!--              </div>-->
<!--            </b-carousel-slide>-->
          </router-link>

          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </b-carousel>
      </div>

    </section>
  </div>
</template>
<!-- 1vw = 1440    -->
<script>
// import homeApi from "@/apis/homeApi";
import banner from "@/assets/banner.png"
export default {
  name: "HomeBanner",
  data(){
    return {
      slide: 0,
      url: require('@/assets/img/new-banner01.jpg'),
      secondUrl: require('@/assets/img/new-banner02.jpg'),
      thirdUrl:require('@/assets/img/new-banner03.jpg'),
    }
  },
  created() {
    //获取Banner图片
    // homeApi.banner(res => {
    //   this.url = res.data.data
    //   // this.secondUrl="https://stavlin.shop/images/app/invite-friends.jpg"
    // })
  },
  methods:{
    toPath(){
      this.$router.push({path: '/auth-login'})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';
@import "@/style/style.scss";
:root{
  //homeBannerHeight:
}
.container {
  max-width: var(--containerWidth);
  padding: 0 15px;
}
.homeBanner{

  ::v-deep {
    #testimonialSwiper {
      .carousel-inner{
        height: 100%;
        .img-fluid{
          height: 100%;
        }
      }
      .carousel-caption{
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
      }
    }

  }
  .carousel-item{

  }
}
#testimonialSwiper .carousel-inner{
  height: 100% !important;
}
button {
  background-color: transparent;
  border: none;
}
.homeBanner{
  color: #161c2d;
}
//@media only screen and (max-width: 991px) {
//  .homeBanner{
//    font-size:0.875rem;
//    line-height: 1.5rem;
//  }
//}
//
@media only screen and (min-width: 576px) {
  .homeBanner {
    //height: ;
  }
}
//
//@media only screen and (min-width: 768px) {
//  .container {
//    max-width: 768px;
//  }
//}
//
//@media only screen and (min-width: 992px) {
//  .container {
//    max-width: 992px;
//  }
//}
//
//@media only screen and (min-width: 1200px) {
//  .container {
//    max-width: 1200px;
//  }
//}
//
//@media only screen and (min-width: 1300px) {
//  .container {
//    max-width: 1350px;
//  }
//}
//
//@media only screen and (min-width: 1400px) {
//  .container {
//    max-width: 1440px;
//  }
//}
//
//.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//  padding-right: var(--bs-gutter-x, 0.9375rem);
//  padding-left: var(--bs-gutter-x, 0.9375rem);
//}

//
//.row {
//  margin-right: -0.625rem;
//  margin-left: -0.625rem
//}
//
//@media only screen and (min-width: 992px) {
//  .row {
//    margin-right: -0.8125rem;
//    margin-left: -0.8125rem
//  }
//}
//
//.row > * {
//  padding-right: 0.625rem;
//  padding-left: 0.625rem
//}
//
//@media only screen and (min-width: 992px) {
//  .row > * {
//    padding-right: 0.8125rem;
//    padding-left: 0.8125rem
//  }
//}
//
//
//
//.hero__slider--section.style2 {
//  padding: 1.875rem 0;
//}

.title-heading {
  //line-height: 26px;
  color: #161c2d;
  .btn{
    background-color: rgba(255, 190, 61, 1) !important;
    border-color: rgba(255, 190, 61, 1) !important;
  }
}

.title-heading .heading {
  font-size: 45px !important;
  line-height: 1;
  letter-spacing: 1px;
}


.title-heading .para-desc {
  font-size: 18px;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;

}

//@media (max-width: 1200px) {
//  .title-heading .heading {
//    font-size: 18px !important;
//  }
//  .title-heading .para-desc {
//    font-size: 12px;
//  }
//  .title-heading .btn{
//    font-size: 14px;
//    padding: 5px 16px;
//  }
//}
//
@media (max-width: 780px) {
  .title-heading .heading {
    font-size: 35px !important;
  }
  .title-heading .para-desc {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; // 显示2行
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title-heading .btn{
    font-size: 12px;
    padding: 2px 10px;
  }
}

@media (max-width: 650px) {
  .title-heading .heading {
    font-size: 28px !important;
  }
  .title-heading .para-desc {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; // 显示2行
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title-heading .btn{
    font-size: 12px;
    padding: 2px 10px;
  }
}


@media (max-width: 600px) {
  .title-heading .heading {
    font-size: 24px !important;
  }
  .title-heading .para-desc {
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 显示2行
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title-heading .btn{
    font-size: 12px;
    padding: 2px 10px;
  }
}



@media (max-width: 500px) {
  .container{
    height: 230px !important;
  }
  .title-heading .heading {
    font-size: 18px !important;
  }
  .title-heading .para-desc {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 显示2行
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title-heading .btn{
    font-size: 12px;
    padding: 2px 10px;
  }
}


@media (min-width: 1200px) {
  .container{
    height: 600px !important;
  }
}
</style>
